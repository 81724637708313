import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Sidebar/footer'
import 'bootstrap/dist/css/bootstrap.min.css';


const PrivateLayout = ({ children }) => {

    return (

        <div className="dashboard__wrap">
            <Sidebar />
            {/* <div className='main__wrap'> */}
                {children}
            {/* </div> */}
            <Footer />
        </div>
    )
}

export default PrivateLayout
