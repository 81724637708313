

import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import { NavLink, useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import moment from 'moment';
import enter from '../../src/assets/img/enter.png'
import copy from '../../src/assets/img/copy.png'

import { Pagination } from "antd";

const Usertouser = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currency, setCurrency] = useState('');
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        transactionType:  "userFundTransfer"

      };

      let response = await _fetch(`${base_url}api/user/allTxns`, 'post', requestData);
      console.log('response ', response)
      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }




  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };
  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <>
            <div className="container p-2">
              <div className="card p-2">

                {/* <div className="card-header bg-white">
                  <button className={(row?.side == 'BUY') ? 'badge btn  bg-success' : 'badge btn  bg-danger'} style={{ float: 'left' }}>{row?.side} </button>
                  <p className='text-center' style={{ left: '24%', top: '9px', position: 'absolute' }}>{row?.exchangeName}</p>
                  <p className='text-center' style={{ right: '0px', top: '7px', position: 'absolute' }}>{row?.symbol} <img src={enter} onClick={() => handleStatusChange(row._id, 'openview')} style={{ height: '40px' }} /> </p>
                  <p className='text-center' style={{ right: '0px', top: '9px', position: 'absolute' }}></p>

                </div> */}
                <div className="card-body p-0">
                  <div className="row openPositionsDiv">
                    <div className="col-md-6">
                      <dl className="row mb-0 positionRow">
                        <dt className="col-5">Token Name:</dt>
                        <dd className="col-7"><small>{row?.tokenName}</small></dd>
                        <dt className="col-5">Form:</dt>
                        <dd className='col-7' >  {row?.from}</dd>
                        <dt className="col-5">Amount:</dt>
                        <dd className={`col-7 ${row?.amount < 0 ? 'text-danger' : 'text-success'}`}>  {row?.amount}</dd>
                        <dt className="col-5">Created At:</dt>
                        <dd className="col-7">{dateFormat(row?.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>

                      </dl>

                    </div>
                    <div className="col-md-6">
                      <dl className="row mb-0 positionRow">
                        <dt className="col-5">Wallet Type</dt>
                        <dd className="col-7">{row?.walletType === 'WALLET' ? 'Fuel wallet' : 'Work wallet'}</dd>
                        <dt className="col-5">To:</dt>
                        <dd className='col-7 '>  {row?.to}</dd>
                        <dt className="col-5">Transaction Type :</dt>
                        <dd className="col-7">{row?.transactionType}</dd>

                        <dt className="col-5">Updated At:</dt>
                        <dd className="col-7">{dateFormat(row?.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                      </dl>
                    </div>

                  </div>
                </div>
              </div>
            </div>



          </>

        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13" className='text-center'>Data Not Found</td>
        </tr>
      );
    }
  };

  const resetFilters = () => {
    setStartDate('');
    setEndDate('');
    setCurrency('');
    setUserId('');
    // Reset filtered data to show all data again
    handledashboard(currentPage, 10); // Optionally fetch all data again
  };

  return (
    <>
      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5 p-2">
              <div className="card back-card">
                <div className='main-12 p-0'>
                  <div className='row'>

                    <div className='col-lg-6 col-md-6 col-sm-6 col-6 p-1'>
                      <input type='date' className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6 p-1'>
                      <input type='date' className='form-control' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                    {/* <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <select name="currency" className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                        <option value="">Select Currency</option>
                        <option value="XRP">XRP</option>
                        <option value="BTC">BTC</option>
                        <option value="BNB">BNB</option>
                        <option value="SHIB">SHIB</option>
                      </select>
                    </div> */}


                    {/* <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
                                                <div className='search-btn'>
                                                    <input type="text" placeholder="User Id Search" value={userId} onChange={(e) => setUserId(e.target.value)} />
                                                </div>
                                            </div> */}
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6 p-1'>
                      <div className='reset-btn'>
                        <button className='apply-btn' onClick={() => handledashboard(1, 10)}>Apply Filters</button>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-6 p-1'>
                      <div className='place-2'>
                        <button onClick={resetFilters} className="btn btn-secondary">Reset Filters</button>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>

            {records()}

            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Usertouser
