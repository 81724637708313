import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import dateFormat from "dateformat";
const Closespotposition = () => {
    const [data, setData] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0);

    const handledashboard = async (page = 1, pageSize = 10) => {
        try {
            const skip = (page - 1) * pageSize;
            setpageSer(skip);
            const requestData = {
                limit: pageSize,
                skip: skip,
                type: "SPOT",


            };

            let response = await _fetch(`${base_url}api/user/orders`, 'post', requestData);

            if (response.success === true) {
                setData(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }


    useEffect(() => {
        handledashboard(currentPage, 10);
    }, [currentPage]);
    const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);

    };

    const records = () => {
        if (data && data?.data && data?.data?.length > 0) {
            return data?.data?.map((row, i) => {
                const serialNumber = pageSer + i + 1;
                return (
                    <div key={i} className="container mt-5">
                        <div className="card border-primary">
                            <div className="card-header bg-white d-flex justify-content-between">
                                <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <dl className="row mb-0">
                                            <dt className="col-sm-5">OrderId:</dt>
                                            <dd className="col-sm-7">{row?.orderId}</dd>
                                            <dt className="col-sm-5">Symbol:</dt>
                                            <dd className="col-sm-7">{row?.symbol}</dd>
                                            <dt className="col-sm-5">status:</dt>
                                            <dd className="col-sm-7">{row?.status}</dd>
                                            <dt className="col-sm-5">Order Price:</dt>
                                            <dd className="col-sm-7">{row?.price}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-md-4">
                                        <dl className="row mb-0">
                                            <dt className="col-sm-5">OrigQty</dt>
                                            <dd className="col-sm-7">{row?.origQty}</dd>
                                            <dt className="col-sm-5">Type</dt>
                                            <dd className="col-sm-7 text-danger">{row?.type}</dd>
                                            <dt className="col-sm-5">Side</dt>
                                            <dd className="col-sm-7">{row?.side}</dd>
                                            <dt className="col-sm-5">Quantity:</dt>
                                            <dd className="col-sm-7 text-secondary">{row?.quantity}</dd>
                                            <dt className="col-sm-5">OrderType:</dt>
                                            <dd className="col-sm-7">{row?.orderType}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-md-4">
                                        <dl className="row mb-0">
                                            <dt className="col-sm-5">ExchangeName:</dt>
                                            <dd className="col-sm-7">{row?.exchangeName}</dd>
                                            <dt className="col-sm-5">BatchId</dt>
                                            <dd className="col-sm-7 text-danger">{row?.batchId}</dd>
                                            <dt className="col-sm-5">Date:</dt>
                                            <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                                        </dl>
                                    </div>
                                    <div className="d-grid gap-2 d-md-block mt-2">
                                        <button className="btn btn-danger" type="button">Close</button>
                                        <button className="btn btn-primary" type="button">View</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return <div>
                <p className='text-center mt-5'>Data Not Found</p>
            </div>;
        }
    };
    return (
        <>


            {/* <div className="section2">

                <div className="table-sec">
                    <div className="transactions">
                    {records()}
                    </div>
                    <div className="pagination">
                        <Pagination
                            style={{ cursor: "pointer" }}
                            className="mx-auto"
                            current={currentPage || 1}
                            total={data?.count || 0}
                            pageSize={10}
                            onChange={handlePaginationChange}
                            showSizeChanger={false}
                            showQuickJumper={false}
                        />

                    </div>

                </div>
            </div> */}
            <div className="section2">

                <div className="table-sec">
                    <div className="transactions">
                        <div className="container mt-5">
                            <div className="card">
                                <div className='main-12'>
                                    <div className='row'>

                                        <div className='col-lg-4 col-md-4 col-sm-6 col-6'>
                                            <div className=''>
                                                <input type='date' className='form-control' name="start_date" />
                                            </div>

                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-6 col-6'>
                                            <div className=''>
                                                <input type='date' className='form-control' name="start_date" />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-6 col-6'>
                                            <div className=''>
                                                <button type='date' className='btn'><i className='fa fa-search'></i></button>
                                            </div>

                                        </div>



                                    </div>



                                    <div className="main-table-2 mt-3">
                                        <select name="kycStatus" className="input01" id="status">
                                            <option value="">Select Currency</option>
                                            <option value="1">PENDING</option>
                                            <option value="2">APPLIED</option>
                                            <option value="3">APPROVED</option>
                                            <option value="4">REJECTED</option>
                                        </select>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                placeholder="User Id Search"
                                            />
                                            <button>
                                                <i className="fa fa-search"></i> {/* Search Icon */}
                                            </button>
                                        </div>






                                        {/* Filter Button */}
                                        <div className="filter-btn">
                                            <button>
                                                <i className="fa fa-filter"></i> {/* Filter Icon */}
                                                Filters
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {records()}

                        <div className="pagination">
                            <Pagination
                                style={{ cursor: "pointer" }}
                                className="mx-auto"
                                current={currentPage || 1}
                                total={data?.count || 0}
                                pageSize={10}
                                onChange={handlePaginationChange}
                                showSizeChanger={false}
                                showQuickJumper={false}
                            />

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Closespotposition
