import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import moment from 'moment';
import { useNavigate } from 'react-router';
import LogoutModal from '../Auth/LogoutModal';
import { NavLink } from 'react-router-dom';

const Withdraw = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [amount, SetAmount] = useState('');
  const [name, setName] = useState({});
  const [tag, setTag] = useState('');
  const [walletAddress, setWalletAddress] = useState('')
  const [url, setUrl] = useState('')
  const [otp, setOtp] = useState('')
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('0');
  const handledashboard = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/dashboard`, 'get', {});

      if (response.success === true) {
        setData(response?.data?.balance);
        setName(response?.data.user);
        setUrl(response?.data?.url)
        setTag(response?.data)
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  const handlewithdraw = async () => {

    try {
      const requestData = {
        otp: otp,
        walletAddress: walletAddress,
        amount: amount,
        withrawType: selectedOption === '1' ? 'FUEL' : 'address'

      }
      let response = await _fetch(`${base_url}api/user/placeWithraw`, 'post', requestData, {});

      if (response.success === true) {
        toast.dismiss()
        toast.success(response.message)
        setOtp('');
        setWalletAddress('');
        SetAmount('');
        handledashboard()
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  useEffect(() => {
    handledashboard();
  }, []);

  const copyText = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.dismiss()

    toast.success("Text copied to clipboard!");
  };
  const handleotp = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/withrawOtp`, 'get', {});

      if (response.success === true) {
        toast.success(response.message)
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  return (
    <>
      <div className="container mt-1 p-1">
        <div className='row text-h2 m-auto padding-form'>

          <h1 className='text-center ' style={{ marginTop: '50px' }}></h1>
          <h3 class="scan text-center">Withdraw</h3>
          <form>
            <h3 class="scan text-center comming-soon">Available Balance :
              {tag?.incomeBalance?.toFixed(2)}
            </h3>



            <div className='ph_sec'>

              <div className="mb-3 mt-4">
                <label for="exampleInputPassword1" class="form-label user-text">selected the value</label>
                <select
                  className="form-control input form-select user-text"
                  aria-label="Default select example"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option value="0">selected</option>
                  <option value="1">Fuel</option>
                  <option value="2">Address</option>
                </select>
              </div>
            </div>

            {selectedOption === '2' && (
              <>
                <div class="mb-3 mt-3">
                  <div className='in_put_field d-flex mar'>
                    <label for="exampleInputPassword1" class="form-label user-text">Address</label>

                  </div>

                </div>

                <div className='ph_sec'>
                  <div class="mb-3">

                    <div className='in_put_field d-flex mt-3'>
                      <div className='in_in'>
                        <input type="text" class="form-control user-text" id="exampleInputPassword1" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} />
                      </div>
                      <div className='icon_set-22' >
                        <i class="fa-solid fa-copy fa-fade" onClick={() => copyText(url)} ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className='ph_sec'>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label user-text">Quantity</label>
                <div className='in_put_field d-flex'>
                  <div className='in_in'>
                    <input type="text" class="form-control user-text" id="exampleInputEmail1" value={amount} aria-describedby="emailHelp" onChange={(e) => SetAmount(e.target.value)} />

                  </div>

                </div>
              </div>
            </div>


            <div className='ph_sec'>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label user-text">OTP</label>
                <div className='in_put_field d-flex'>
                  <div className='in_in'>

                    <input
                      type="text"
                      className="form-control user-text"
                      id="exampleInputPassword1"
                      placeholder=''
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}

                    />
                  </div>
                  <div className='icon_set' >
                    <button class="beat-fade" onClick={handleotp} type="button"><span className='get-otp'>Get OTP</span></button>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-md-12">
              <div className='card border-primary'>
                <div className='card-body p-2'>
                  <dl className="row mb-0 positionRow">
                    <dt className="col-6 Amount">Transaction Fee </dt>
                    {<dd className="col-6 p-0 text-center">1$</dd>}
                    {<dt className="col-6">Arrival quantity</dt>}
                    {<dd className="col-6 p-0 text-center">0 USDT</dd>}
                  </dl>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className='card border-primary'>
                <div className='card-body p-2'>
                  <p className='position-Row'>
                    Opertion Reminder
                  </p>
                  <p className='position-parg'>
                    The minimum amount of single withdrawal is : 10 USDT , please do not transfer USDT assets to addresses other than USDT , otherwise it cannot be retrieved in the above address you transferred.
                  </p>
                </div>
              </div>
            </div>

            <div class="mb-3 d-flex mt-5">

              <button className='custom-btn btn-3 d-flex m-auto" ' type='button' onClick={handlewithdraw} >Confirm</button>

              <NavLink to='/withdrawhistory'><button className='custom-btn btn-5 d-flex m-auto ' type='button' >withdraw history</button></NavLink>



            </div>


          </form>

        </div >
      </div >



    </>
  )
}

export default Withdraw
