import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import dateFormat from "dateformat";
import { NavLink } from 'react-router-dom';
const Team = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currency, setCurrency] = useState('');
  const [userId, setUserId] = useState('');
  const [pioneerActives, setPioneerActives] = useState('')
  const [pioneerEnActives, setPioneerEnActives] = useState('')
  const [binanceActives, setBinanceActives] = useState('')
  const [binanceEnActives, setBinanceEnActives] = useState('')
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,



      };

      let response = await _fetch(`${base_url}api/user/teamMembers`, 'post', requestData);

      if (response.success === true) {
        setData(response);
        setPioneerActives(response?.pioneerActives)
        setPioneerEnActives(response?.pioneerEnActives)
        setBinanceActives(response?.binanceActives)
        setBinanceEnActives(response?.binanceEnActives)
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };

  console.log('data', data?.data);
  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return (
        <div className="table-sec">
          <div className="transactions">
            <div className="container p-2">
              <section className="sec02_d">
                <div className="container p-0 ">
                  <div className="table_over sec4_box table-responsive">
                    <table className="dashboard_table">
                      <thead>
                        <tr className="table_row">
                          <th className="table_heading wc b_boot">S.No</th>
                          <th className="table_heading wc b_boot">Level</th>
                          <th className="table_heading wc b_boot">Count</th>
                          <th className="table_heading wc b_boot">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data.map((row, i) => {
                          const serialNumber = pageSer + i + 1;
                          return (
                            <tr key={i}>
                              <td className="table_data b_boot">
                                {serialNumber}
                              </td>
                              <td className="table_data b_boot grc">
                                {/* <NavLink to={`/level/${row?._id}`}> */}
                                  {row?.level}
                                {/* </NavLink> */}
                              </td>
                              <td className="table_data b_boot gc text-warning">
                                {/* <NavLink to={`/level/${row?._id}`}> */}
                                  {row?.count}
                                {/* </NavLink> */}
                              </td>
                              <td className="table_data b_boot gc text-warning">
                                <NavLink to={`/level/${row?._id}`}>
                                  <button className='btn btn-info'>View</button>
                                </NavLink>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center text-light mt-5">Data Not Found</p>
        </div>
      );
    }
  };


  const resetFilters = () => {
    setStartDate('');
    setEndDate('');
    setCurrency('');
    setUserId('');
    // Reset filtered data to show all data again
    handledashboard(currentPage, 10); // Optionally fetch all data again
  };

  return (
    <>



      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5 p-2">
              <ul class="nav nav-pills nav-fill mt-3">
                <li class="nav-item team-current">
                  <NavLink class="nav-link secend-page " aria-current="page" to="/team">Team</NavLink>
                </li>
                <li class="nav-item direct-2">
                  <NavLink class="nav-link" to="/direct">Direct Team</NavLink>
                </li>


              </ul>
              <div className="container mt-2 p-0">
                <div className='main-boz p-0'>
                  <div className='row text-h2 m-auto'>
                    <div className="card border-primary">

                      <div className="card-body p-2">
                        <div className="row">
                          <div className="col-6">
                            <dl className="row mb-0 positionRow">
                              <dt className="col-9 p-0 Amount">Pioneer Activate </dt>
                              {<dd className="col-3 p-0 text-center">{pioneerActives}</dd>}
                              {<dt className="col-9 p-0">Binance Activate</dt>}
                              {<dd className="col-3 p-0 text-center">{binanceActives}</dd>}


                            </dl>
                          </div>
                          <div className="col-6">
                            <dl className="row mb-0 positionRow">
                              {<dt className="col-9 p-0">Pioneer Inactivate</dt>}
                              {<dd className="col-3 p-0 text-center">{pioneerEnActives}</dd>}


                              {<dt className="col-9 p-0">Binance Inactivate</dt>}
                              {<dd className="col-3 p-0 text-center">{binanceEnActives}</dd>}
                            </dl>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {records()}


            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div >
    </>
  )
}

export default Team
