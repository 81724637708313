
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import { Pagination } from "antd";
const Openspotposition = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        type: "SPOT",

      };

      let response = await _fetch(`${base_url}api/user/trades`, 'post', requestData);

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }




  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };


  const handleStatusChange = (userId, selectedValue) => {
    let url = "";

    switch (selectedValue) {
      case "openview":
        url = `/openspotview?id=${userId}`;
        break;

      default:
        return;
    }


    navigate(url);
  };

  

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <div key={i} className="container mt-5">
            <div className="card border-primary">
              <div className="card-header bg-white d-flex justify-content-between align-items-center">
                <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
              </div>
              <div className="card-body">
                <div className="row  openPositionsDiv">
                  <div className="col-4">
                    <dl className="row mb-0 positionRow">
                      <dt className="col-sm-5">OrderId:</dt>
                      <dd className="col-sm-7">{row?.orderId}</dd>
                      <dt className="col-sm-5">Symbol:</dt>
                      <dd className="col-sm-7">{row?.symbol}</dd>
                      <dt className="col-sm-5">status:</dt>
                      <dd className="col-sm-7">{row?.status}</dd>
                      <dt className="col-sm-5">Order Price:</dt>
                      <dd className="col-sm-7">{row?.price}</dd>
                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Order Type</dt>
                      <dd className="col-sm-7">{row?.orderType}</dd>
                      <dt className="col-sm-5">Exchange Name</dt>
                      <dd className="col-sm-7 text-danger">{row?.exchangeName}</dd>
                      <dt className="col-sm-5">Leverage</dt>
                      <dd className="col-sm-7">{row?.leverage}</dd>
                      <dt className="col-sm-5">Type:</dt>
                      <dd className="col-sm-7 text-secondary">{row?.type}</dd>
                      <dt className="col-sm-5">Side:</dt>
                      <dd className="col-sm-7">{row?.side}</dd>

                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">end Price:</dt>
                      <dd className="col-sm-7">{row?.endPrice}</dd>
                      <dt className="col-sm-5">Start Price</dt>
                      <dd className="col-sm-7 text-danger">{row?.startPrice}</dd>
                      <dt className="col-sm-5">profit :</dt>
                      <dd className={`  col-sm-7  ${row?.profitLoss < 0 ? 'text-red' : 'text-green'}`}>{row?.profitLoss}</dd>

                      <dt className="col-sm-5">Date:</dt>
                      <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                      <dt className="col-sm-5">CloseStatus:</dt>
                      <dd className="col-sm-7">
                        {row?.closeStatus === true ? (

                          <>
                            <p style={{ color: "red" }}>Closed</p>
                          </>
                        ) : (
                          <>
                            <p style={{ color: "green" }}>open</p>
                          </>

                        )}
                      </dd>
                    </dl>
                  </div>
                  <div className="d-grid gap-2 d-md-block mt-2">
                    <button className="btn btn-danger" type="button">Close</button>
                    <button className="btn btn-primary" type="button">View</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div >
        <p className='text-center mt-5 '>Data Not Found</p></div>;
    }
  };

  return (
    <>

      {/* <div className="section2">

        <div className="table-sec">
          <div className="transactions">

            {records()}


            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div> */}
      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5">
              <div className="card start-date">
                <div className='main-12'>
                  <div className='row'>

                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <input type='date' className='form-control' name="start_date" />
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <input type='date' className='form-control' name="start_date" />
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <button type='date' className='btn'><i className='fa fa-search'></i></button>
                    </div>
                  </div>



                  <div className="main-table-2 mt-3">
                    {/* <select name="kycStatus" className="input01" id="status">
                      <option value="">Select Currency</option>
                      <option value="1">PENDING</option>
                      <option value="2">APPLIED</option>
                      <option value="3">APPROVED</option>
                      <option value="4">REJECTED</option>
                    </select> */}
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="User Id Search"
                      />
                      {/* <button>
                        <i className="fa fa-search"></i> 
                      </button> */}
                    </div>






                    {/* Filter Button */}
                    <div className="filter-btn">
                      <button>
                        <i className="fa fa-filter"></i> {/* Filter Icon */}
                        Filters
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {records()}

            <div className="pagination mt-3">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Openspotposition
