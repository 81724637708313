import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import moment from 'moment';
import { Pagination } from "antd";
import chartp from '../assets/img/chartp.png'



const MarketData = () => {
  const [data, setData] = useState()
  const [balance, setBalance] = useState()
  const [marketData, setmarketdata] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [wallet, setWallet] = useState()
  const handledashboard = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/marketData`, 'get', {});
      console.log('resp is ', response)
      if (response.success == true) {
        setmarketdata(response?.data);

      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  console.log({ marketData });



  const handlewallet = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        walletType: "DEPOSIT",
        transactionType: "accountActivation"



      };
      let response = await _fetch(`${base_url}api/user/walletLedgar`, 'post', requestData);
      console.log('resp is ', response)
      if (response.success == true) {
        setWallet(response);

      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }

  const handleactivateaccount = async (id) => {
    try {
      const requestData = {
        id: id


      };




      let response = await _fetch(`${base_url}api/user/activateAccount`, 'post', requestData);
      console.log('resp is ', response)
      if (response.success == true) {
        setBalance(response?.data);
        toast.success(response.message)
        handledashboard()
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }

  const handlepackage = async () => {
    try {


      let response = await _fetch(`${base_url}api/user/packages`, 'GET', {});
      console.log('response ', response)
      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }




  useEffect(() => {
    handlepackage();
    handledashboard()
    handlewallet(currentPage, 10)
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };
  console.log('wallet', wallet);

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {

        return (
          <>





          </>

        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13" className='text-center text-light m-auto d-flex mt-2'>Data Not Found</td>
        </tr>
      );
    }
  };
  const record = () => {
    if (wallet && wallet?.data && wallet?.data?.length > 0) {
      return wallet?.data?.map((row, i) => {

        return (
          <>
            <div className="container p-1">
              <div className="card p-1">
                <div className="card-body">

                </div>
              </div>
            </div>




          </>

        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13" className='text-center text-light m-auto d-flex mt-2'>Data Not Found</td>
        </tr>
      );
    }
  };

  console.log('balance', balance);



  return (
    <>
      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5 p-2">
              <section className="sec02_d">
                <div className="container p-0 ">
                  <div className="table_over sec4_box table-responsive">
                    <div className="table-responsive">
                      <table className="dashboard_table">
                        <tbody>
                          <tr className="table_row">
                            <th className="table_heading wc b_boot ">Coin</th>
                            <th className="table_heading wc b_boot ">Name</th>
                            <th className="table_heading wc b_boot ">Symbol</th>
                            <th className="table_heading wc b_boot ">Price </th>
                            <th className="table_heading wc b_boot text-light ">Market Cap</th>
                          </tr>

                          {marketData.map((item, index) => {
                            return (
                              <tr>
                                <td className="table_data b_boot" key={index}>
                                  <Link to='/dashboard'>
                                    <div className="">

                                      <span className="wc table_text"> {item?.image ? <img src={item?.image} alt="Image" /> : <span>No Image Available</span>}</span>
                                    </div>
                                  </Link>
                                </td>
                                <td className="table_data b_boot grc">
                                  <Link to='/dashboard'>{item?.name}</Link></td>
                                <td className="table_data b_boot gc text-warning"><Link to='/dashboard'>{(item?.symbol).toUpperCase()}</Link></td>
                                <td className="table_data b_boot gcmm"><Link to='/dashboard'>{item?.price}</Link></td>
                                <td className="table_data b_boot gc text-light"><Link to='/dashboard'>{item?.marketCap}</Link></td>


                              </tr>
                            )
                          })}




                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>

            </div>


          </div>

        </div>
      </div>
    </>
  )
}

export default MarketData
