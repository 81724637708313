export const _fetch = async (url, method, data, header) => {
    const LoginToken = localStorage.getItem("token");
    // console.log(method , "from _fetch fun: LoginToken", LoginToken);

    if (method === 'get' || method === 'GET') {
        return fetch(url,
            {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": LoginToken,
                },

            })
            .then((response) => {
                return response.json() 
            })
            .then((response) => {
                console.log('response in api case 2' , response)
                return response;
            })
            .catch((error) => {
                return { 'status': 0, 'message': 'Something went wrong, ERROR: ' + error }

            })
        // .done();
    } else if (method === 'patch' || method === 'PATCH') {
        return fetch(url,
            {
                method: method,
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    "Authorization": LoginToken,
                },
                body: data,

            })
            .then((response) => response.json())
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return { 'status': 0, 'message': 'Something went wrong, ERROR: ' + error }

            })
        // .done();
    } else if (method === 'delete' || method === 'DELETE') {
        return fetch(url,
            {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": LoginToken,
                },
                body: JSON.stringify(data),

            })
            .then((response) => response.json())
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return { 'status': 0, 'message': 'Something went wrong, ERROR: ' + error }

            })
        // .done();
    } else {
        return fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": LoginToken,

            },
            body: JSON.stringify(data),
        }).then((response) => response.json())
            .then((result) => {
                console.log('result is ' , result)
                if(result.success == false && result.message == "Unauthorized!"){
                    localStorage.removeItem("token")
                    return result;
                }else{
                    return result;
                }
                
            })
            .catch((error) => {
                return { 'status': 0, 'message': 'Something went wrong, ERROR: ' + error }

            })
    }

}
export default _fetch;