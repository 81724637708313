// import React from 'react'

// const Market = () => {
//   return (
//    <>
//    </>
//   )
// }

// export default Market


import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import { NavLink, useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import moment from 'moment';
import enter from '../../src/assets/img/enter.png'
import copy from '../../src/assets/img/copy.png'
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { Pagination } from "antd";

const Market = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [selectedTab, setSelectedTab] = useState('1day');
  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10, type = '') => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,


      };

      let apiUrl = `${base_url}api/user/randomProfit`;
      if (type === '30day') {
        apiUrl += '?type=30day';
      }
      if (type === '90day') {
        apiUrl += '?type=90day';
      }

      let response = await _fetch(apiUrl, 'GET', {});
      console.log('response ', response)
      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  const handleTabChange = (tab) => {
    setSelectedTab(tab); // Update the selected tab state
  };




  // useEffect(() => {
  //   handledashboard(currentPage, 10);
  // }, [currentPage]);
  // const handlePaginationChange = (pageOne) => {
  //   setCurrentPage(pageOne);

  // };

  console.log('data', data);


  useEffect(() => {
    handledashboard(currentPage, 10, selectedTab);
  }, [selectedTab, currentPage]);
  // const handlePaginationChange = (pageOne) => {
  //   setCurrentPage(pageOne);

  // };
  console.log('data----', data);

  const records = () => {
    if (data && data?.randomUsers?.length > 0) {
      return data?.randomUsers?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <>
            <tr >
              <td className='td-1'>
                <div className='markit-td1'>
                  <td className='up-trade'>
                    {row?.pic ? <img src={row?.pic} alt="Image" /> : <span>No Image Available</span>}
                  </td>
                </div>
              </td>
              <td className='up-trade text-warning'> {row?.country}</td>
              <td className='up-trade '> {row?.uid}</td>
              <td className='up-trade '> {row?.email}</td>
              <td className='up-trade winning amount text-success'> {row?.pnl}</td>




            </tr>
          </>

        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13" className='text-center text-light m-auto d-flex mt-2'>Data Not Found</td>
        </tr>
      );
    }
  };
  return (
    <>
      <div class="container mt-2  mb-2 p-1">
        <div className='col-md-12'>
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${selectedTab === '1day' ? 'active' : ''}`}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected={selectedTab === '1day' ? 'true' : 'false'}
                onClick={() => handleTabChange('1day')}
              >
                1 Day
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${selectedTab === '30day' ? 'active' : ''}`}
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected={selectedTab === '30day' ? 'true' : 'false'}
                onClick={() => handleTabChange('30day')}
              >
                30 Day
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${selectedTab === '90day' ? 'active' : ''}`}
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected={selectedTab === '90day' ? 'true' : 'false'}
                onClick={() => handleTabChange('90day')}
              >
                90 Day
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div class="container mt-2  mb-4 p-1">
        <div className='col-md-12'>
          <div className='market-table'>
            <table className="table-responsive mb-4">
              <thead>
                <tr>
                  <th className='text-center' scope="col">Flag</th>
                  <th scope="col">Country</th>
                  <th className='' scope="col">UID</th>

                  <th className='' scope="col">Email</th>
                  <th scope="col">Winning</th>
                </tr>
              </thead>
              <tbody>
                {/* */}
                {records()}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </>
  )
}

export default Market
