import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import dateFormat from "dateformat";
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const Level = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currency, setCurrency] = useState('');
  const [userId, setUserId] = useState('');
  const { level } = useParams()

  console.log('level', level);


  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        level: level,



      };

      let response = await _fetch(`${base_url}api/user/teamByLevel`, 'post', requestData);

      if (response.success === true) {
        setData(response);

      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };

  console.log('data', data);
  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return (
        <div className="user-list-container mt-2 p-2">
          {data?.data.map((row, i) => {
            const serialNumber = pageSer + i + 1;
            return (
              <div key={i} className="user-card">
                <div className="user-header">
                  <span className="user-id">
                    <i className="fas fa-user-circle" /> {row?.downlineId?.userId} ({row?.downlineId?.name})
                  </span>
                  {/* <span className="user-id">
                    <i className="fas fa-id-card" />
                    </span> */}
                </div>
                <div className="user-content">
                  <div className="icon">
                    <i className="fas fa-envelope" />
                  </div>
                  <div className="value" title={row?.downlineId?.email}>
                    {row?.downlineId?.email}
                  </div>
                  <div className="icon">
                    <i className="fas fa-id-badge" />
                  </div>
                  <div className="value">{row?.downlineId?.sponserId}</div>
                  <div className="icon">
                    <i className="fas fa-calendar-alt" />
                  </div>
                  <div className="value">{dateFormat(row?.downlineId?.createdAt, "yyyy-mm-dd")}</div>
                </div>
                <div className="user-footer">
                  <div
                    className={`status ${row?.downlineId?.paidStatus ? 'status-true' : 'status-false'}`}
                  >
                    <i className={row?.downlineId?.paidStatus ? 'fas fa-check-circle' : 'fas fa-times-circle'} />
                    <span>Paid: {row?.downlineId?.paidStatus ? 'Yes' : 'No'}</span>
                  </div>
                  <div
                    className={`status ${row?.downlineId?.isVerified ? 'status-true' : 'status-false'}`}
                  >
                    <i className={row?.downlineId?.isVerified ? 'fas fa-check-circle' : 'fas fa-times-circle'} />
                    <span>Verified: {row?.downlineId?.isVerified ? 'Yes' : 'No'}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-center mt-5">Data Not Found</p>
        </div>
      );
    }
  };
  const resetFilters = () => {
    setStartDate('');
    setEndDate('');
    setCurrency('');
    setUserId('');
    // Reset filtered data to show all data again
    handledashboard(currentPage, 10); // Optionally fetch all data again
  };

  return (
    <>



      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5 p-2">
              <ul class="nav nav-pills nav-fill mt-3">
                <li class="nav-item team-current">
                  <NavLink class="nav-link secend-page " aria-current="page" to="/team">Team</NavLink>
                </li>
                <li class="nav-item direct-2">
                  <NavLink class="nav-link" to="/direct">Direct Team</NavLink>
                </li>


              </ul>

            </div>

            {records()}


            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Level
