


import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { useLocation } from 'react-router-dom';
import { Pagination } from "antd";
import dateFormat from "dateformat";

const Openview = () => {
    const [data, setData] = useState();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setPageSer] = useState(0);

    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);
    const id = queryParams.get('id');

    const handledashboard = async (page = 1, pageSize = 10) => {
        try {
            const skip = (page - 1) * pageSize;
            setPageSer(skip);
            const requestData = {
                limit: pageSize,
                skip: skip,
                id: id
            };

            let response = await _fetch(`${base_url}api/user/tradeDetails`, 'post', requestData);

            if (response.success === true) {
                setData(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }

    useEffect(() => {
        handledashboard(currentPage, 10);
    }, [currentPage]);

    const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);
    };

    const records = () => {
        if (data && data?.data && data?.data?.length > 0) {
            return data?.data?.map((row, i) => {
                const serialNumber = pageSer + i + 1;
                return (
                    <div key={i} className="container mt-5">
                        <div className="card border-primary">
                            <div className="card-header bg-white d-flex justify-content-between align-items-center">
                                <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-4">
                                        <dl className="row mb-0 positionRow">
                                            <dt className="col-sm-5">Status:</dt>
                                            <dd className="col-sm-7">{row?.status}</dd>
                                            <dt className="col-sm-5">Type:</dt>
                                            <dd className="col-sm-7">{row?.type}</dd>
                                            <dt className="col-sm-5">Start Price:</dt>
                                            <dd className="col-sm-7">{row?.price}</dd>
                                            <dt className="col-sm-5">Order Status:</dt>
                                            <dd className="col-sm-7">{row?.quantity}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-4">
                                        <dl className="row mb-0 positionRow">
                                            <dt className="col-sm-5">User Id:</dt>
                                            <dd className="col-sm-7">{row?.userId}</dd>
                                            <dt className="col-sm-5">Order Id:</dt>
                                            <dd className="col-sm-7 text-danger">{row?.orderId}</dd>
                                            <dt className="col-sm-5">Price:</dt>
                                            <dd className="col-sm-7">{row?.price}</dd>
                                            <dt className="col-sm-5">Mode:</dt>
                                            <dd className="col-sm-7 text-secondary">{row?.side}</dd>
                                            <dt className="col-sm-5">Profit:</dt>
                                            <dd className="col-sm-7">{row?.quantity}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-4">
                                        <dl className="row mb-0 positionRow">
                                            
                                            <dt className="col-sm-5">End Price:</dt>
                                            <dd className="col-sm-7 text-danger">134.806</dd>
                                            <dt className="col-sm-5">Date:</dt>
                                            <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                                        </dl>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return <div>Data Not Found</div>;
        }
    };

    return (
        <>
            
            <div className="section2">

                <div className="table-sec">
                    <div className="transactions">
                        <div className="container mt-5">
                            <div className="card">
                                <div className='row'>
                                    <div className='main-12'>

                                        <div className=''>
                                            <input type='date' className='form-control' name="start_date" />
                                        </div>
                                        <div className=''>
                                            <input type='date' className='form-control' name="start_date" />
                                        </div>
                                        <div className=''>
                                            <button type='date' className='btn'><i className='fa fa-search'></i></button>
                                        </div>
                                    </div>



                                    <div className="main-table-2 mt-3">
                                        
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                placeholder="User Id Search"
                                            />
                                            <button>
                                                <i className="fa fa-search"></i> {/* Search Icon */}
                                            </button>
                                        </div>






                                        {/* Filter Button */}
                                        <div className="filter-btn">
                                            <button>
                                                <i className="fa fa-filter"></i> {/* Filter Icon */}
                                                Filters
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {records()}

                        <div className="pagination">
                            <Pagination
                                style={{ cursor: "pointer" }}
                                className="mx-auto"
                                current={currentPage || 1}
                                total={data?.count || 0}
                                pageSize={10}
                                onChange={handlePaginationChange}
                                showSizeChanger={false}
                                showQuickJumper={false}
                            />

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Openview;

