import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination, Row } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import dateFormat from "dateformat";
import { NavLink } from 'react-router-dom';
const DashBonus = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  // const [pageSer, setpageSer] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currency, setCurrency] = useState('');
  const [userId, setUserId] = useState('');

  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        transactionType: "levelIncome",



      };

      let response = await _fetch(`${base_url}api/user/bonusTxns`, 'post', requestData);

      if (response.success === true) {
        setData(response);

      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };

  console.log('data', data);

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <div key={i} className="container mt-1">
            {/* <div className="card border-primary"> */}
            {/* <div className="card-body p-2"> */}
            <div className="row">
              <div className="col-md-12">
                <div className='card border-primary'>
                  <div className='card-body p-2'>
                    <dl className="row mb-0 positionRow">
                      <dt className="col-7  Amount">${row.amount}</dt>
                      {<dt className="col-5">{dateFormat(row?.updatedAt, "yyyy-mm-dd, hh:mm")}</dt>}

                      {<dd className="col-7 ">{row.tokenName}</dd>}
                      {<dd className="col-5">{row.transactionType}</dd>}
                      {<dd className="col-12">{row.description}</dd>}
                      {<dd className="col-12">{row.from}</dd>}
                      {/* {<dd className="col-6 p-0">Side:{row.side}</dd>}
                      {<dd className="col-6 p-0">To:{row.to}</dd>}  */}

                      {/* {<dt className="col-4 p-2"> date{row.updatedAt}</dt>} */}
                      {/* {<dt className="col-6">Wallet Type{row.walletType}</dt>} */}
                    </dl>
                  </div>
                </div>
              </div>




            </div >
            {/* </div> */}
            <div div className="col-md-6" >
              <dl className="row mb-0 positionRow">
              </dl>
            </div >



            {/* </div> */}
          </div >
        );
      });
    } else {
      return <div>
        <p className='text-center text-light mt-5'>Data Not Found</p>
      </div>;
    }
  };
  const resetFilters = () => {
    setStartDate('');
    setEndDate('');
    setCurrency('');
    setUserId('');
    // Reset filtered data to show all data again
    handledashboard(currentPage, 10); // Optionally fetch all data again
  };


  return (
    <>



      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5 p-2">
              <div className="rounded  p-2 mt-3">
                <h3 className='scan text-center'>Bonus</h3>
                <div className="col mt-4">
                  <ul class="nav nav-pills nav-fill p-0">
                    <li class="nav-item team-current">
                      <NavLink class="nav-link secend-page " aria-current="page" to="/direct">Level Income</NavLink>
                    </li>
                    <li class="nav-item direct-2">
                      <NavLink class="nav-link" to="/club">Rewards Bonus</NavLink>
                    </li>
                    <li class="nav-item direct-2">
                      <NavLink class="nav-link" to="/layalty">Royalty Bonus</NavLink>
                    </li>


                  </ul>

                </div>
              </div>
              
            </div>


            {records()}


            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default DashBonus
