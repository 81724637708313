import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import { NavLink, useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import moment from 'moment';
import enter from '../../src/assets/img/enter.png'
import copy from '../../src/assets/img/copy.png'
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { Pagination } from "antd";
import Photoroom from '../assets/img/Photoroom.png'


const Profit = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [selectedTab, setSelectedTab] = useState('1day');
  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10, type = '') => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        time: type,

      };

      const apiUrl = `${base_url}api/user/profitCalculation`;
      let response = await _fetch(apiUrl, 'post', requestData); // Pass requestData with type
      console.log('response ', response);
      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };


  useEffect(() => {
    handledashboard(currentPage, 10, selectedTab);
  }, [selectedTab, currentPage]);

  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };
  const records = () => {
    if (data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <>
        
            <div key={i} className="container mt-1 p-0">
              <div className="card border-primary">

                <div className="card-body p-2">
                  <div className="row">
                    <div className="col-md-6">
                      <dl className="row mb-0 positionRow">

                        <dt className="col-5">Date</dt>
                        <dd className="col-7 p-0">{moment(row.date).format("YY-MM-DD")}</dd>
                        <dt className="col-5">Quantity</dt>
                        <dd className="col-7 p-0">{row?.usdValue}</dd>
                      </dl>
                    </div>
                    <div className="col-md-6">
                      <dl className="row mb-0 positionRow">
                        <dt className="col-5">Profit</dt>
                        <dd className="col-7 text-success p-0">{row?.pnl}</dd>



                      </dl>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </>

        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13" className='text-center text-light m-auto d-flex mt-2'>Data Not Found</td>
        </tr>
      );
    }
  };
  return (
    <>
      <div className="container mt-5 p-0">
        <div id="carouselExampleCaptions" className="carousel slide phonepe-carousel" data-bs-ride="carousel" data-bs-interval="3000">
          <span className='profit-main text-light'>{selectedTab === '1day' && 'Daily Profit' || selectedTab === '7day' && 'Weekly Profit' || selectedTab === '1month' && 'Monthly Profit' || selectedTab === 'overall' && 'Overall Profit'}</span>

          <div
            className="carousel-inner"
            style={{
              backgroundImage: `url(${Photoroom})`,
              height: '300px',
              backgroundSize: 'cover',
              backgroundPosition: '100%',
            }}

          />

          <span className='profit-box'>{data?.totalProfit?.toFixed(2)}</span>

        </div>
      </div>

      <div class="container mt-2  mb-2 p-1 mt-3">
        <div className='col-md-12'>
          <ul className="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${selectedTab === '1day' ? 'active' : ''}`}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected={selectedTab === '1day' ? 'true' : 'false'}
                onClick={() => handleTabChange('1day')}
              >
                1 Day
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${selectedTab === '7day' ? 'active' : ''}`}
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected={selectedTab === '7day' ? 'true' : 'false'}
                onClick={() => handleTabChange('7day')}
              >
                7 Day
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${selectedTab === '1month' ? 'active' : ''}`}
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected={selectedTab === '' ? 'true' : 'false'}
                onClick={() => handleTabChange('1month')}
              >
                30 Day
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${selectedTab === 'overall' ? 'active' : ''}`}
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected={selectedTab === 'overall' ? 'true' : 'false'}
                onClick={() => handleTabChange('overall')}
              >
                All
              </button>
            </li>

          </ul>
        </div>
      </div>

      <div class="container mt-2  mb-4 p-1">
        <div className='col-md-12'>

          <div className='market-table'>
            {records()}
          </div>
        </div>

      </div>
      <div className="pagination">
        <Pagination
          style={{ cursor: "pointer" }}
          className="mx-auto"
          current={currentPage || 1}
          total={data?.count || 0}
          pageSize={10}
          onChange={handlePaginationChange}
          showSizeChanger={false}
          showQuickJumper={false}
        />

      </div>
    </>
  )
}

export default Profit
